import React, { Component } from 'react';
import CustomizedCampaignTable from '../components/CustomizedCampaignTable';
import firebase from "firebase";

class Campaigns_ads extends Component {

    state = {
      campaignData: [],
      isMobile: false,
      allData:[],
      filtertxt: ''
    }

    componentWillMount(){
      let ref = firebase.database().ref('campaign_ads');

      ref.on('value', snapshot => {
        const campaignData = [];
        

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;

          campaignData.push({ id:item.key, change: item.change ,name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name });
          
        });

        var filterData = campaignData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));

        this.setState({ campaignData: filterData, allData: campaignData });
      });

      window.addEventListener("resize", this.handleResize );
    }

    search (e) {
      var filterData = this.state.allData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
  
      this.setState({ campaignData: filterData, filtertxt:e.target.value.toLowerCase()  });
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    render() {
      return (
          <div>
           <CustomizedCampaignTable 
              rows={[
                { id: 'name', numeric: false, disablePadding: true, label: 'Campaign Name', sortable: true },
                { id: 'template', numeric: false, disablePadding: false, label: 'Template', sortable: true },
              ]} 
              data={this.state.campaignData}
              orderBy={'created'}
              movefile={false}
              ads={true}
              isMobile={this.state.isMobile}
              search={(e) => {this.search(e)}}
              dataName='campaign_ads'/>
          </div>
      );
    }
}
  
export default Campaigns_ads;