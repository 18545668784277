import React, { Component } from 'react';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import firebase from "firebase";

class RemoteControl extends Component {

  constructor(props) {
    super(props);

    var newWidth = (window.innerHeight - 100) * 1080;
    this.subscriberProperties = {
      preferredFrameRate: 15,
      showControls: false,
      height: window.innerHeight - 100,
      width: newWidth / 1920,
      subscribeToAudio: false,
    };

    this.subscriberEventHandlers = {
      videoDisabled: event => {
        console.log('Subscriber video disabled!');
      },
      videoEnabled: event => {
        console.log('Subscriber video enabled!');
      }
    };
    
  }

  MouseDown = event => {

    let touch_x = event.nativeEvent.offsetX;
    let touch_y = event.nativeEvent.offsetY;

    console.log(touch_x + ":" + touch_y)

    firebase.database().ref('/push_station/-M4IBnyEiNOe9rb75XnJ/touch_x').transaction(function (current_value) {
      return touch_x;
    });

    firebase.database().ref('/push_station/-M4IBnyEiNOe9rb75XnJ/touch_y').transaction(function (current_value) {
      return touch_y;
    });
  }

  componentWillUnmount(){
    firebase.database().ref('/push_station/-M4IBnyEiNOe9rb75XnJ/remote').transaction(function (current_value) {
      return false;
    });
  }

    render() {

      return (
        <div  onClick={this.MouseDown}>
          <OTSession apiKey="46716462" sessionId="2_MX40NjcxNjQ2Mn5-MTU4ODU3MDc5MDU5MH5ieThRRndPOEczWWdGV1BocGdVNnhNL25-fg" token="T1==cGFydG5lcl9pZD00NjcxNjQ2MiZzaWc9Nzc5NzAyMTgxOTI2OWMyMDJlNDRhYTA3OGE1ZDk4YmVhNTkwYjY5MTpzZXNzaW9uX2lkPTJfTVg0ME5qY3hOalEyTW41LU1UVTRPRFUzTURjNU1EVTVNSDVpZVRoUlJuZFBPRWN6V1dkR1YxQm9jR2RWTm5oTkwyNS1mZyZjcmVhdGVfdGltZT0xNTg4NTcxMDAwJm5vbmNlPTAuMDk4NTE0NzUwNzgzOTAzNyZyb2xlPXN1YnNjcmliZXImZXhwaXJlX3RpbWU9MTU5MTE2MzAwMCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==">
            <OTStreams >
              <OTSubscriber
                properties={this.subscriberProperties}
                eventHandlers={this.subscriberEventHandlers}
              />
            </OTStreams>
          </OTSession>
        </div>
      );
    }
}
  
export default RemoteControl;