import React, { Component } from 'react';
import CustomizedStationTable from '../components/CustomizedStationTable';
import firebase from "firebase";

class Stations extends Component {

    state = {
      stationData: [],
      isMobile: false,
      allData:[],
      filtertxt: ''
    }

    componentDidMount(){
      let ref = firebase.database().ref('station');

      ref.on('value', snapshot => {
        const stationData = [];

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;

          //if(item.zone === 'default')
          //{
            stationData.push({ 
              id:item.key, 
              name: item.name, 
              created: item.created, 
              updated: item.updated, 
              campaign: item.campaign ? item.campaign : null,
              connected: item.connected,
              zone: item.zone,
              sub_zone: item.sub_zone,
              used: item.used,
              app_version: item.app_version ? item.app_version : "1.0.0",
              is_playing: item.is_playing ? true : false,
              push_date: item.push_date ? item.push_date : "0000000000",
              sim_serial: item.sim_serial ? item.sim_serial : "0",
              total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null",
              camera: item.camera ? true : false
            });
          //}
        
          //mcd
          /*if(item.key == "-LhPJWzIUSYUusIIyAr8" || 
              item.key == "-LimhofMexBlvoW4vvhs" || 
              item.key == "-LhOR7PnMztVMwnIPqBA" || 
              item.key == "-LhOR6-ksGFSwqLmmV65" || 
              item.key == "-LiwJSeaQADGuZIYhx5z" || 
              item.key == "-LhJoKQP2vol2RdLh2_3" || 
              item.key == "-Lp8JlJCAwah2tbLT8TS" || 
              item.key == "-LhcjuWBfASkpvQtua7J" || 
              item.key == "-M8PdaFugZRYgY3eTMGQ" || 
              item.key == "-LhPJMT3YPH4Km8UQyzG" || 
              item.key == "-Lr-Mo8uJ7Nc43yzYrO1" || 
              item.key == "-Lhcj7ii-GHpwjMDEgvs" || 
              item.key == "-LjvWQBel0lrzItSKomj" || 
              item.key == "-LjvW5QYMluvGnsTLEPC" || 
              item.key == "-LjvWd-PdnvFPjV1dcN5" || 
              item.key == "-LjvVLg4Gjk9bIqlh9QD" || 
              item.key == "-LhF6nJZnNym1Hgs0FFW" || 
              item.key == "-Lr-MlYwQA3iPQf-csoY" || 
              item.key == "-Limj9BL9aQ5FpWQt75c" || 
              item.key == "-Lr-MfrUzYRi3VZFp20z" || 
              item.key == "-LrMaIMzqRpH5mgAC1i0" ||
              item.key == "-LimhaiFOM6JLvme7rot" ||
              item.key == "-LrM_SI5j1tmSMALIHfX" ||
              item.key == "-LhjFivhG4GqIx9cnAcu" ||
              item.key == "-LhjFphhgkEvbaacjPvx" 
          ){
            firebase.database().ref("station/" + item.key).update({ exclude_file: [
              "-NDR0hy3Y-erGW38Cbhd",
              "-NDBEBcqAIgICwrc4f4v",
              "-NGLD67QdytLmoGaHheO"
             ] });
            firebase.database().ref("push_station/" + item.key).update({ push: true, message: 'push' });
          }*/
        });


        
        

        var filterData = stationData.filter(item => item.name ? item.name.toLowerCase().includes(this.state.filtertxt):"" );

        const windowSize = window.innerWidth;
        if(windowSize < 800){
          this.setState({ isMobile: true, stationData: filterData, allData: stationData })
        } else {
          this.setState({ isMobile: false, stationData: filterData, allData: stationData })
        }

      });

      window.addEventListener("resize", this.handleResize );
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    search (e) {
      var filterData = this.state.allData.filter(item => item.name ? item.name.toLowerCase().includes(e.target.value.toLowerCase()): "");
  
      this.setState({ stationData: filterData, filtertxt:e.target.value.toLowerCase()  });
    }

    searchReset(){
      var filterData = this.state.allData.filter(item => item.name ? item.name.toLowerCase().includes(this.state.filtertxt):"");
  
      this.setState({ stationData: filterData, filtertxt:""  });
    }

    render() {
      return (
          <div style={{ padding: 0 }}>
           <CustomizedStationTable 
              rows={this.state.isMobile ? [
                { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true }
              ] : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                { id: 'is_playing', numeric: false, disablePadding: false, label: 'Play', sortable: true },
                { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true },
                { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
              ]} 
              data={this.state.stationData}
              orderBy={'created'}
              movefile={false}
              dataName='station'
              isMobile={this.state.isMobile}
              search={(e) => {this.search(e)}}
              searchReset = {() => {this.searchReset()}}
              />
          </div>
      );
    }
}
  
export default Stations;