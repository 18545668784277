import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import {sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CustomizedTable from './CustomizedTable'
import Paper from '@material-ui/core/Paper';
import AutoFitImage from 'react-image-autofit-frame';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import { compose } from "recompose";
import ReactTimeout from 'react-timeout'
import { MovieRounded } from '@material-ui/icons';
import ReactPlayer from 'react-player'
import DayPicker from './DayPicker'
import { Calendar } from 'react-date-range';
import moment from "moment";
import TimeKeeper from 'react-timekeeper';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    root: {
        width: '100%',
    },
    container: {
      border: '5px solid #f50057'
    },
    mediaContainer: {
      position: 'absolute',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: 10,
      flex: 1,
    },
});

const windowHeight = window.innerHeight - 120

const SortableItem = sortableElement(({deleteItem, value, index, allindex,  handleToggle, checked, mediaCollection, allContainer, currentContainer, currentSelectedContent}) => 
{

  var currentIndex = mediaCollection.map(val => {
    return val.id
  }).indexOf(value.content);

  if(value.schedule){
    if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
      value.active = false
    } else {
      value.active = true
    }
  } else {
    value.active = true
  }
  console.log(currentIndex)
  console.log(value.content)
  console.log(mediaCollection[currentIndex].type)
 
  
  
  if(mediaCollection[currentIndex].type == "video/mp4"){

    return(
      <ListItem style={{ backgroundColor: value.active ? null : "#ff8d8d", width: '100%' }} role={undefined}  disableRipple key={index} selected={allindex == currentSelectedContent ? true : false } divider dense button>
         <Typography style={{ fontSize: 20, color: value.schedule ? "#0030ff" : "#efefef" ,paddingTop: 5 }} variant="caption" display="block" gutterBottom>
         •
        </Typography>
        <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
          {allindex+1}
        </Typography>
        <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
        <ListItemText primary={mediaCollection[currentIndex].name} />
        
      </ListItem>
    )
  } else {
    
    return(
      <ListItem style={{ backgroundColor: value.active ? null : "#ff8d8d", width: '100%' }} role={undefined}  disableRipple key={index} selected={allindex == currentSelectedContent ? true : false } divider dense button>
        <Typography style={{ fontSize: 20, color: value.schedule ? "#0030ff" : "#efefef" ,paddingTop: 5 }} variant="caption" display="block" gutterBottom>
         •
        </Typography>
        <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
          {allindex+1}
        </Typography>
        <Avatar src={mediaCollection[currentIndex].imageUrl} >
        </Avatar>
        <ListItemText primary={mediaCollection[currentIndex].name} />
        
      </ListItem>
    )
  }

})

const SortableContainer = sortableContainer(({children}) => {
  return <List>{children}</List>;
});

var timeout = [];

class CustomizedAddCampaign extends Component {

    state = {
        selectedTemplate: 'default',
        selectedCampaignAds: 'none',
        templateCollection: [],
        stationCollection: [],
        stationCollectionNoChange: [],
        campaignAdsCollection: [],
        contents: [[]],
        allContainer: [''],
        openDialog: false,
        openSecondDialog: false,
        dialogSecondOption: '',
        dialogSecondTitle: '',
        dialogOption: '',
        dialogTitle: '',
        mediaData:[],
        folderCollection: [],
        currentContainer: 0,
        checked: [],
        campaignName: '',
        mediaCollection: [],
        contentDuration: 0,
        transitionSelected: 'fade',
        dialogTitleVisible: true,
        currentSlideIndex: [0],
        created: null,
        currentCampaignId: null,
        selectedStartDate:  moment(new Date()),
        selectedEndDate: moment(new Date()),
        selectedStartTime: '12:00 am',
        selectedEndTime: '11:59 pm',
        selectedSecondStartDate:  moment(new Date()),
        selectedSecondEndDate: moment(new Date()),
        selectedSecondStartTime: '12:00 am',
        selectedSecondEndTime: '11:59 pm',
        switchChecked: false,
        switchSecondChecked: false,
        stretchValue: true,
        stretchSelected: false,
        fullScreenDialog: false,
        currentSelectedContent: 0,
        isMobile: false,
        total_mix_content_duration: 0,
        selectedCampaignInterval: 3,
        percentage_tc: 0,
        percentage_ytn: 0,
        newPostKey: null
    }

    componentDidMount(){

      let folderRef = firebase.database().ref('media_folder');

          folderRef.once('value', foldersnapshot => {
            const folderCollection = [];

            foldersnapshot.forEach(childFolderSnapshot => {
                const folderItem = childFolderSnapshot.val();
                folderItem.key = childFolderSnapshot.key;
                
                folderCollection.push({ id:folderItem.key, name: folderItem.name, created: folderItem.created, total: folderItem.total });
                
            });

            let ref = firebase.database().ref('media');

            ref.once('value', snapshot => {
                const mediaCollection = [];

                snapshot.forEach(childSnapshot => {
                  const item = childSnapshot.val();
                  item.key = childSnapshot.key;
                  
                  mediaCollection.push({id:item.key, duration: item.duration, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder });
                  
                });

              

                let templateRef = firebase.database().ref('template');

                templateRef.once('value', templateSnapshot => {
                    const templateCollection = [];

                    templateSnapshot.forEach(templateChildSnapshot => {
                        const templateItem = templateChildSnapshot.val();
                        templateItem.key = templateChildSnapshot.key;
                        templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
                    });

                    let campaignAdsRef = firebase.database().ref('campaign_ads');

                    campaignAdsRef.once('value', campaignAdsSnapshot => {
                          var campaignAdsCollection = [];
              
                          
                          campaignAdsSnapshot.forEach(campaignAdsChildSnapshot => {
                              const campaignAdsItem = campaignAdsChildSnapshot.val();
                              campaignAdsItem.key = campaignAdsChildSnapshot.key;
                              
                              campaignAdsCollection.push({ id:campaignAdsItem.key, name: campaignAdsItem.name, created: campaignAdsItem.created, updated: campaignAdsItem.updated, template: campaignAdsItem.template, template_name: campaignAdsItem.template_name, content: campaignAdsItem.content });
                              
                              
                              
                          });

                          let stationRef = firebase.database().ref('station');

                          stationRef.once('value', stationSnapshot => {
                                var stationCollection = [];
                                var stationCollection2 = [];
                                
                                stationSnapshot.forEach(stationChildSnapshot => {
                                    const stationItem = stationChildSnapshot.val();
                                    stationItem.key = stationChildSnapshot.key;

                                    
                                    stationCollection.push({ 
                                      id:stationItem.key, 
                                      name: stationItem.name, 
                                      created: stationItem.created, 
                                      updated: stationItem.updated, 
                                      campaign: stationItem.campaign ? stationItem.campaign : null,
                                      connected: stationItem.connected,
                                      zone: stationItem.zone,
                                      used: stationItem.used,
                                      app_version: stationItem.app_version ? stationItem.app_version : "1.0.0",
                                      is_playing: stationItem.is_playing ? true : false,
                                      push_date: stationItem.push_date ? stationItem.push_date : "0000000000",
                                      sim_serial: stationItem.sim_serial ? stationItem.sim_serial : "0",
                                      total_file_in_app: stationItem.total_file_in_app ? stationItem.total_file_in_app : "null"
                                    });

                                    stationCollection2.push({ 
                                      id:stationItem.key, 
                                      name: stationItem.name, 
                                      created: stationItem.created, 
                                      updated: stationItem.updated, 
                                      campaign: stationItem.campaign ? stationItem.campaign : null,
                                      connected: stationItem.connected,
                                      zone: stationItem.zone,
                                      used: stationItem.used,
                                      app_version: stationItem.app_version ? stationItem.app_version : "1.0.0",
                                      is_playing: stationItem.is_playing ? true : false,
                                      push_date: stationItem.push_date ? stationItem.push_date : "0000000000",
                                      sim_serial: stationItem.sim_serial ? stationItem.sim_serial : "0",
                                      total_file_in_app: stationItem.total_file_in_app ? stationItem.total_file_in_app : "null"
                                    });

                                });

                                this.storeCollection(folderCollection, mediaCollection, templateCollection, campaignAdsCollection, stationCollection, stationCollection2)
                          });
                    });
              

                    
                });
            });
      })

      /*let folderRef2 = firebase.database().ref('media_folder');

        folderRef2.on('value', foldersnapshot => {
            const folderCollection = [];

            foldersnapshot.forEach(childFolderSnapshot => {
                const folderItem = childFolderSnapshot.val();
                folderItem.key = childFolderSnapshot.key;
                
                folderCollection.push({ id:folderItem.key, name: folderItem.name, created: folderItem.created, total: folderItem.total });
                
            });

            let ref = firebase.database().ref('media');

            ref.on('value', snapshot => {
                const mediaCollection = [];

                snapshot.forEach(childSnapshot => {
                  const item = childSnapshot.val();
                  item.key = childSnapshot.key;
                  
                  mediaCollection.push({id:item.key, duration: item.duration, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder });
                  
                });

              

                let templateRef = firebase.database().ref('template');

                templateRef.once('value', templateSnapshot => {
                    const templateCollection = [];

                    templateSnapshot.forEach(templateChildSnapshot => {
                        const templateItem = templateChildSnapshot.val();
                        templateItem.key = templateChildSnapshot.key;
                        templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
                    });

                    this.setState({ folderCollection, mediaCollection, templateCollection });
                });
            });
      })*/

      const windowSize = window.innerWidth;
        if(windowSize < 800){
          this.setState({ isMobile: true })
        } else {
          this.setState({ isMobile: false })
        }

      
      window.addEventListener("resize", this.handleResize );
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    storeCollection = (folderCollection,  mediaCollection, templateCollection, campaignAdsCollection, stationCollection, stationCollection2) =>{

      
      //edit campaign
      if(this.props.editCampaign){

        let ref = this.props.ads ? firebase.database().ref('campaign_ads/'+ this.props.editCampaignId) : firebase.database().ref('campaign_tc/'+ this.props.editCampaignId);

        ref.once('value', snapshot => {
          const item = snapshot.val();
          item.key = snapshot.key;
          const campaignName = item.name

          const contents = [[]]
          const allContainer = []
          const currentSlideIndex = []
          var total_mix_content_duration = 0;


          var duplicate_count = 0;
          var ads_count = 0;
          var tc_count = 0;
          
          if(item.template !== 'default')
          {
            const currentIndex = templateCollection.map(value => { return value.id}).indexOf(item.template)
            templateCollection[currentIndex].container.map((value, index) => { 

              if(item.content && item.content[index])
              {
                allContainer.push(item.content[index][0].content)
                contents.push(item.content[index])
              } else {
                allContainer.push("")
                contents.push([])
              }

              currentSlideIndex.push(0)
            })
            
            this.setState({ selectedCampaignInterval: item.ads_interval ? item.ads_interval : 3, selectedCampaignAds : item.ads_content ? item.ads_content : 'none' , switchChecked: item.schedule ? true : false, 
              selectedStartDate: item.schedule ? moment.unix(item.schedule.start_date) : moment(new Date()),
              selectedEndDate: item.schedule ? moment.unix(item.schedule.end_date) : moment(new Date()),
              selectedStartTime: item.schedule ? item.schedule.start_time : '12:00 am',
              selectedEndTime: item.schedule ? item.schedule.end_time : '11:59 pm',
              selectedSecondStartDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.start_date) : moment(new Date()),
              selectedSecondEndDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.end_date) : moment(new Date()),
              selectedSecondStartTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.start_time :'12:00 am',
              selectedSecondEndTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.end_time :'12:00 am',
              switchSecondChecked: item.content && item.content[0] && item.content[0][0].schedule ? true : false,
              currentCampaignId: item.key, 
              created: item.created, campaignName, 
              contentDuration: item.content && item.content[0] && item.content[0][0].duration? item.content[0][0].duration : null, 
              transitionSelected: item.content && item.content[0] ? item.content[0][0].transition : 'fade', 
              folderCollection, mediaCollection, templateCollection, selectedTemplate: item.template, allContainer, contents: contents, currentSlideIndex, campaignAdsCollection });
            
          } else {

            if(item.ads_content != 'none' && item.ads_content){
              
              const currentIndex = campaignAdsCollection.map(value => { return value.id }).indexOf(item.ads_content);
      
              var duplicate_count = 0;
              var ads_count = 0;
              var tc_count = 0;
              var total_second_ads = 0
              var total_second_tc = 0
              var percentage_tc = 0;
              var percentage_ytn = 0;

              const contents = [[]];
              const ads_active = [];
              

              if(campaignAdsCollection[currentIndex].content[0].length * (item.ads_interval ? item.ads_interval - 1 : 2) > item.content[0].length){
        
                
                var total_content_number = campaignAdsCollection[currentIndex].content[0].length * (item.ads_interval ? item.ads_interval : 3);
                
                for (var i = 0; i < total_content_number; i++) {
                  
                  if(i == duplicate_count){
        
                    ads_active.push({ active: true});
                    contents[0].push(campaignAdsCollection[currentIndex].content[0][ads_count]);
                    
                    if(ads_count > campaignAdsCollection[currentIndex].content[0].length - 2){
                      ads_count = 0;
                    } else {
                      ads_count++;
                    }
        
                    duplicate_count = i + (item.ads_interval ? item.ads_interval : 3);


                  } else {
                    
                    ads_active.push({ active: false});
                    contents[0].push(item.content[0][tc_count]);
      
                    if(tc_count > item.content[0].length - 2){
                      tc_count = 0;
                    } else {
                      tc_count++;
                    }
                  }
        
                } 
         
              } else {
                var total_content_number = item.content[0].length / (item.ads_interval ? item.ads_interval - 1 : 2) + item.content[0].length;
        
                for (var i = 0; i < Math.round(total_content_number); i++) {
                  
                  if(i == duplicate_count){
        
                    ads_active.push({ active: true});
                    contents[0].push(campaignAdsCollection[currentIndex].content[0][ads_count]);
        
                    if(ads_count > campaignAdsCollection[currentIndex].content[0].length - 2){
                      ads_count = 0;
                    } else {
                      ads_count++;
                    }
        
                    duplicate_count = i + (item.ads_interval ? item.ads_interval : 3);
                  } else {
                    
                    ads_active.push({ active: false});
                    contents[0].push(item.content[0][tc_count]);
        
                    if(tc_count > item.content[0].length - 2){
                      tc_count = 0;
                    } else {
                      tc_count++;
                    }
                  }
                }
              }

              for (var i = 0; i < contents[0].length; i++) {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_mix_content_duration += mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_mix_content_duration += parseInt(contents[0][i].duration)
                }

                if(ads_active[i].active){
                  if(contents[0][i].type == "video/mp4"){
                    const currentIndex2 = mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
                    total_second_ads += mediaCollection[currentIndex2].duration;
                  } 
                  else 
                  {
                    total_second_ads += parseInt(contents[0][i].duration)
                  }
                }
                else
                {
                  if(contents[0][i].type == "video/mp4"){
                    const currentIndex2 = mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
                    total_second_tc += mediaCollection[currentIndex2].duration;
                  } 
                  else 
                  {
                    total_second_tc += parseInt(contents[0][i].duration)
                  }
                }
              }

            }
           
            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;

            currentSlideIndex.push(0)

            if(item.content && item.content[0])
            {
              allContainer.push(item.content[0][0].content)
            } else {
              allContainer.push('')
            }

            item.content[0].map(value => { 
              if(value.schedule){
                if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
                  value.active = false
                } else {
                  value.active = true
                }
              } else {
                value.active = true
              }
            })

            this.setState({ percentage_ytn, percentage_tc, selectedCampaignInterval: item.ads_interval ? item.ads_interval : 3, total_mix_content_duration, contents : item.content, selectedCampaignAds : item.ads_content ? item.ads_content : 'none', switchChecked: item.schedule ? true : false, 
              selectedStartDate: item.schedule ? moment.unix(item.schedule.start_date) : moment(new Date()),
              selectedEndDate: item.schedule ? moment.unix(item.schedule.end_date) : moment(new Date()),
              selectedStartTime: item.schedule ? item.schedule.start_time : '12:00 am',
              selectedEndTime: item.schedule ? item.schedule.end_time : '11:59 pm',
              selectedSecondStartDate: item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.start_date) : moment(new Date()),
              selectedSecondEndDate: item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.end_date) : moment(new Date()),
              selectedSecondStartTime: item.content[0][0].schedule ? item.content[0][0].schedule.start_time :'12:00 am',
              selectedSecondEndTime: item.content[0][0].schedule ? item.content[0][0].schedule.end_time :'11:59 pm',
              switchSecondChecked: item.content[0][0].schedule ? true : false,
              currentCampaignId: item.key, 
              created: item.created, campaignName, 
              contentDuration: item.content && item.content[0][0].duration? item.content[0][0].duration : null, 
              transitionSelected: item.content?item.content[0][0].transition : 'fade',  
              folderCollection, mediaCollection, templateCollection, stationCollection, stationCollectionNoChange: stationCollection2, selectedTemplate: item.template, allContainer, currentSlideIndex, campaignAdsCollection  });
          }

        });
      } else {
        var newPostKey = firebase.database().ref().child('campaign_tc').push().key;
        this.setState({ newPostKey, folderCollection, mediaCollection, templateCollection, campaignAdsCollection, stationCollectionNoChange: stationCollection2, stationCollection });
      }
    }

    handleCampaignIntervalChange = event => {

      if(this.state.contents[0].length > 0){
        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        var total_mix_content_duration = 0;
  
        const contents = [[]];
        const ads_active = [];
  
        if(this.state.campaignAdsCollection[currentIndex].content[0].length * (event.target.value-1) > this.state.contents[0].length){
  
          var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * event.target.value;
  
          for (var i = 0; i < total_content_number; i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + event.target.value;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
  
        } else {
          var total_content_number = this.state.contents[0].length / (event.target.value-1) + this.state.contents[0].length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + event.target.value;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }

        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;
  
        for (var i = 0; i < contents[0].length; i++) {
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
            total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
          } 
          else 
          {
            total_mix_content_duration += parseInt(contents[0][i].duration)
          }

          if(ads_active[i].active){
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_ads += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_ads += parseInt(contents[0][i].duration)
            }
          }
          else
          {
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_tc += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_tc += parseInt(contents[0][i].duration)
            }
          
          }

        }

        percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
        percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;

        this.setState({ percentage_tc, percentage_ytn, selectedCampaignInterval: event.target.value, total_mix_content_duration })
        
      }
      
      
    }


    handleCampaignAdsChange = event => {

      if(event.target.value != 'none' && this.state.contents[0].length > 0){
        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(event.target.value);
        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        var total_mix_content_duration = 0;
  
        const contents = [[]];
        const ads_active = [];
  
        if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > this.state.contents[0].length){
  
          var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
  
          for (var i = 0; i < total_content_number; i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
  
        } else {
          var total_content_number = this.state.contents[0].length / (this.state.selectedCampaignInterval-1) + this.state.contents[0].length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }
  
        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;

        for (var i = 0; i < contents[0].length; i++) {
          
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
            total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
          } 
          else 
          {
            total_mix_content_duration += parseInt(contents[0][i].duration)
          }

          if(ads_active[i].active){
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_ads += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_ads += parseInt(contents[0][i].duration)
            }
          }
          else
          {
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_tc += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_tc += parseInt(contents[0][i].duration)
            }
          }

        }

        percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
        percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
  
        this.setState({ percentage_tc, percentage_ytn,selectedCampaignAds: event.target.value, total_mix_content_duration })
        
      } 
      else
      {
        var total_mix_content_duration = 0;

        if(this.state.contents[0].length > 0){
          for (var i = 0; i < this.state.contents[0].length; i++) {
            if(this.state.contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
    
              total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
            }
          }

          
        this.setState({ percentage_ytn : 0, percentage_tc : 100, selectedCampaignAds: event.target.value, total_mix_content_duration })
        }

      }
      
    }

    handleToggle = value => (e) => {
      
        /*const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
          checked: newChecked,
        });*/

        console.log(value)
    };

    onSortEnd = ({oldIndex, newIndex}) => {

        var itemsArray = [[]]
        var finalItemsArray = [[]]
  
        finalItemsArray = this.state.contents
        itemsArray = arrayMove(this.state.contents[this.state.currentContainer], oldIndex, newIndex)

        finalItemsArray.map((value, index) => {
          if(index === this.state.currentContainer){
            finalItemsArray[index] = itemsArray
          }
        })

        this.setState({ contents: finalItemsArray });
    };
  
    onSortStart = ({node, index, collection, isKeySorting}) => {
    
        const allContainer = this.state.allContainer.map((value, num) => {
            
            if(num == this.state.currentContainer)
            {
            return this.state.contents[this.state.currentContainer][index].content
            } else {
            return this.state.allContainer[num]
            }
        })

        this.setState({ currentSelectedContent: index })

        if(this.state.contents[this.state.currentContainer][index].type === 'video/mp4')
        {
          if(this.state.contents[this.state.currentContainer][index].schedule){

            this.setState({ 
              selectedSecondStartDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.start_date), 
              selectedSecondEndDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.end_date),
              selectedSecondStartTime: this.state.contents[this.state.currentContainer][index].schedule.start_time,
              selectedSecondEndTime: this.state.contents[this.state.currentContainer][index].schedule.end_time,
              switchSecondChecked: true, allContainer, contentDuration: null, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          } else {
            this.setState({ 
              selectedSecondStartDate:  moment(new Date()),
              selectedSecondEndDate: moment(new Date()),
              selectedSecondStartTime: '12:00 am',
              selectedSecondEndTime: '11:59 pm',
              switchSecondChecked: false, allContainer, contentDuration: null, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          }
          
        } else {

          if(this.state.contents[this.state.currentContainer][index].schedule){
            this.setState({ 
              selectedSecondStartDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.start_date), 
              selectedSecondEndDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.end_date),
              selectedSecondStartTime: this.state.contents[this.state.currentContainer][index].schedule.start_time,
              selectedSecondEndTime: this.state.contents[this.state.currentContainer][index].schedule.end_time,
              switchSecondChecked: true, allContainer, contentDuration: this.state.contents[this.state.currentContainer][index].duration, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          } else {
            this.setState({ 
              selectedSecondStartDate:  moment(new Date()),
              selectedSecondEndDate: moment(new Date()),
              selectedSecondStartTime: '12:00 am',
              selectedSecondEndTime: '11:59 pm',
              switchSecondChecked: false, allContainer, contentDuration: this.state.contents[this.state.currentContainer][index].duration, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          }
         
        }
        
    }

    handleClose = () => {
        
        const currentSlideIndex = []
        if(this.state.selectedTemplate !== 'default'){
          const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
          this.state.templateCollection[currentIndex].container.map((val, index) => {
            clearTimeout(timeout[index])
            timeout[index] = null
            currentSlideIndex.push(0)
          })
        } else {
            clearTimeout(timeout[0])
            timeout[0] = null
            currentSlideIndex.push(0)
        }

        this.setState({ openDialog: false, currentSlideIndex });
    };

    deleteItem =currentIndex => () => {
      console.log(currentIndex)
    };

    handleSecondClose = () => {
      this.setState({ openSecondDialog: false });
    }

    renderSortableItem = () => {
      if(this.state.contents[this.state.currentContainer])
      {
        let totalDuration = 0;
        return this.state.contents[this.state.currentContainer].map((val, index)=>{
            /*var currentIndex = this.state.mediaCollection.map(value => {
              return value.id
            }).indexOf(val.content);

            if(this.state.mediaCollection[currentIndex].type == "video/mp4"){

              totalDuration += parseInt(this.state.mediaCollection[currentIndex].duration);
            }else{
              totalDuration += parseInt(val.duration)
            }
            //console.log(totalDuration)*/

            return(
              <SortableItem  deleteItem={this.deleteItem} currentSelectedContent={this.state.currentSelectedContent} key={`item-${index}`} index={index} allindex={index} value={val} handleToggle={this.handleToggle} checked={this.state.checked} mediaCollection={this.state.mediaCollection} currentContainer={this.state.currentContainer} allContainer={this.state.allContainer} />
            )
          })
      } else {
          return(
            <div></div>
          )
      }
    }

    withinDateTime = (startDate, endDate, startTime, endTime) => {
        
       
      if(startDate && endDate && startTime && endTime){
  
          var start_date = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), new Date(startDate).getDate());
          var end_date = new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate());
          var current_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
          var current_time = new Date();
  
          var start_time = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date(startTime).getHours(), new Date(startTime).getMinutes());
          var end_time = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date(endTime).getHours(), new Date(endTime).getMinutes());
  
          if(start_date <= current_date && end_date >= current_date && new Date(start_time).getHours() === new Date(end_time).getHours() && new Date(startTime).getMinutes() === new Date(endTime).getMinutes()){
              return true
          }
  
          if(start_date <= current_date && end_date >= current_date && current_time >= start_time && current_time <= end_time){
              return true
          }
  
          return false
      }
      else
      {
          return true
      }
    }
  

    renderMixItem = () => {

      if(this.state.selectedCampaignAds != 'none' && this.state.contents[0].length != 0){

        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);

        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        var total_mix_content_duration = 0;

        const contents = [[]];
        const ads_active = [];
        const allContentActive= []
        const adsContents = [];

        this.state.contents[0].map(value => { 
          if(value.schedule){
            if(moment(new Date()).isSameOrBefore(moment.unix(value.schedule.end_date)) &&
                moment(new Date()).isSameOrAfter(moment.unix(value.schedule.start_date)) 
              ) {
                if(moment(new Date()).isBefore(moment(value.schedule.end_time, "h:mm a")) &&
                  moment(new Date()).isAfter(moment(value.schedule.start_time, "hh:mm a"))
                  ) {
                  value.active = true
                } else {
                  value.active = false
                }
            } else {
              value.active = false
            }

          } else {
            value.active = true
          }
        })

        for (var i = 0; i < this.state.contents[0].length; i++) {
          if(this.state.contents[0][i].active)
          {
            allContentActive.push(this.state.contents[0][i])
          }
        }

        this.state.campaignAdsCollection[currentIndex].content[0].map(value => { 
          if(value.schedule){

            if(moment(new Date()).isSameOrBefore(moment.unix(value.schedule.end_date)) &&
                moment(new Date()).isSameOrAfter(moment.unix(value.schedule.start_date)) 
              ) {
                if(moment(new Date()).isBefore(moment(value.schedule.end_time, "h:mm a")) &&
                  moment(new Date()).isAfter(moment(value.schedule.start_time, "hh:mm a"))
                  ) {
                  value.active = true
                } else {
                  value.active = false
                }
            } else {
              value.active = false
            }

            

          } else {
            value.active = true
          }
        })

        for (var i = 0; i < this.state.campaignAdsCollection[currentIndex].content[0].length; i++) {
          if(this.state.campaignAdsCollection[currentIndex].content[0][i].active)
          {
            adsContents.push(this.state.campaignAdsCollection[currentIndex].content[0][i])
          }
        }
        
        if(adsContents.length * (this.state.selectedCampaignInterval - 1) > allContentActive.length){
  
          
          var total_content_number = adsContents.length * this.state.selectedCampaignInterval;
  
          for (var i = 0; i < total_content_number; i++) {
            
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(adsContents[ads_count]);
  
              if(ads_count > adsContents.length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
          
              ads_active.push({ active: false});

              if(allContentActive.length > 0){
                contents[0].push(allContentActive[tc_count]);
              }
              

              if(tc_count > allContentActive.length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
   
        } else {

          var total_content_number = allContentActive.length / (this.state.selectedCampaignInterval - 1) + allContentActive.length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(adsContents[ads_count]);
  
              if(ads_count > adsContents.length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});

              if(allContentActive.length > 0){
                contents[0].push(allContentActive[tc_count]);
  
              }
              
              if(tc_count > allContentActive.length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }
        
        for (var i = 0; i < contents[0].length; i++) {
          
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

            
            if(contents[0][i].active)
            {
              total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
            }
            
          } 
          else 
          {
            if(contents[0][i].active)
            {
              total_mix_content_duration += parseInt(contents[0][i].duration)
            }
            
          }

        }


        let totalDuration = 0;

        if(this.state.contents[this.state.currentContainer].length > 0 ){
          return contents[0].map((value, index)=>{


            var currentIndex = this.state.mediaCollection.map(val => {
              return val.id
            }).indexOf(value.content);

            
            if(this.state.mediaCollection[currentIndex].type == "video/mp4"){

              totalDuration += parseInt(this.state.mediaCollection[currentIndex].duration);
              //console.log(value)
              //console.log(totalDuration)
              return(
                <ListItem disabled={true} style={{ opacity: ads_active[index].active ? 1 : 1, backgroundColor: ads_active[index].active ? "#d8e4ed" : null, width: '100%' }} role={undefined}  key={index} divider dense>
                  <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                    {index+1}
                  </Typography>
                  <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                  <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                </ListItem>
              )
            } else {
              
              totalDuration += parseInt(value.duration);
              //console.log(value)
              //console.log(totalDuration)
              return(
                <ListItem disabled={true} style={{ opacity: ads_active[index].active ? 1 : 1, backgroundColor: ads_active[index].active ? "#d8e4ed" : null, width: '100%' }} role={undefined}  key={index} divider dense>
                  <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                    {index+1}
                  </Typography>
                  <Avatar src={this.state.mediaCollection[currentIndex].imageUrl} >
                  </Avatar>
                  <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                </ListItem>
              )
            }
  
          })
  
          
        }
        else {
          return(
            <div></div>
          )
        }

        
        
      } else {

        const allContentActive= []

        this.state.contents[0].map(value => { 
          if(value.schedule){
            if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
              value.active = false
            } else {
              value.active = true
            }
          } else {
            value.active = true
          }
        })

        for (var i = 0; i < this.state.contents[0].length; i++) {
          if(this.state.contents[0][i].active)
          {
            allContentActive.push(this.state.contents[0][i])
          }
        }

        if(allContentActive.length > 0)
        {
          return allContentActive.map((value, index)=>{

              var currentIndex = this.state.mediaCollection.map(val => {
                return val.id
              }).indexOf(value.content);
            
            
              if(this.state.mediaCollection[currentIndex].type == "video/mp4"){
            
                return(
                  <ListItem style={{ backgroundColor: value.active ? null : "#d8e4ed", width: '100%' }} role={undefined}  key={index} divider dense>
                    <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                      {index+1}
                    </Typography>
                    <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                    <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                  </ListItem>
                )
              } else {
                return(
                  <ListItem style={{ backgroundColor: value.active ? null : "#d8e4ed", width: '100%' }} role={undefined}  key={index} divider dense>
                    <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                      {index+1}
                    </Typography>
                    <Avatar src={this.state.mediaCollection[currentIndex].imageUrl} >
                    </Avatar>
                    <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                    
                  </ListItem>
                )
              }

            })
        } else {
            return(
              <div></div>
            )
        }
      }
      
    }

    renderSortable = () => {
        return (
            <SortableContainer lockAxis="y" onSortEnd={this.onSortEnd} onSortStart={this.onSortStart}>
                <List>
                {this.renderSortableItem()}
                </List>
            </SortableContainer>
        )
    }

    renderDurationCampaign = () => {
      const formatted = moment.utc(this.state.total_mix_content_duration*1000).format('HH:mm:ss');
      var durationCampaign = "Duration of Campaign: " + formatted;

        return(durationCampaign)
    }

    renderMixContents = () => {
      return (
        <List>
        {this.renderMixItem()}
        </List>
      )
    }
    
    handleStationChange = (index) => event => {

      if(this.props.editCampaign){
        const stationCollection = [...this.state.stationCollection];
        const stationCollectionNoChange = [...this.state.stationCollectionNoChange];
  
        if(stationCollection[index].campaign == this.props.editCampaignId)
        {
          if(stationCollectionNoChange[index].campaign == this.props.editCampaignId)
          {
            stationCollection[index].campaign = null;
          }
          else{
            stationCollection[index].campaign = stationCollectionNoChange[index].campaign;
          }
          
        }
        else
        {
          stationCollection[index].campaign = this.props.editCampaignId
        }
  
        this.setState({ stationCollection  })
      }

      else
      {
        const stationCollection = [...this.state.stationCollection];
        const stationCollectionNoChange = [...this.state.stationCollectionNoChange];
  
        if(stationCollection[index].campaign == this.state.newPostKey)
        {
          stationCollection[index].campaign = stationCollectionNoChange[index].campaign;
        }
        else
        {
          stationCollection[index].campaign = this.state.newPostKey
        }
  
        this.setState({ stationCollection  })
      }
      
    }

    renderStationItem = () => {
      
      if(this.props.editCampaign){
        return this.state.stationCollection.map((value, index) => {

          if(value.campaign == this.props.editCampaignId){
            return(
              <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                <Checkbox
                  checked={true}
                  value="primary"
                  onChange={this.handleStationChange(index)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                  {index+1}
                </Typography>
                <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                <ListItemText primary={value.name} />
              </ListItem>
            )
          }
          else{
            return(
              <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                <Checkbox
                  checked={false}
                  value="primary"
                  onChange={this.handleStationChange(index)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                  {index+1}
                </Typography>
                <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                <ListItemText primary={value.name} />
              </ListItem>
            )
          }
          
        })
      }
      else{
        return this.state.stationCollection.map((value, index) => {

          if(value.campaign == this.state.newPostKey){
            return(
              <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                <Checkbox
                  checked={true}
                  value="primary"
                  onChange={this.handleStationChange(index)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                  {index+1}
                </Typography>
                <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                <ListItemText primary={value.name} />
              </ListItem>
            )
          }
          else{
            return(
              <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                <Checkbox
                  checked={false}
                  value="primary"
                  onChange={this.handleStationChange(index)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                  {index+1}
                </Typography>
                <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                <ListItemText primary={value.name} />
              </ListItem>
            )
          }
          
        })
      }
      
    }

    renderStation = () => {
      return (
        <List>
        {this.renderStationItem()}
        </List>
      )
    }

    renderTemplate = () => {

      if(this.state.selectedTemplate !== 'default'){
        
        const templateData = [];
        this.state.templateCollection.map((value, index) => {
          if(value.id === this.state.selectedTemplate)
          {
            value.container.map((val, index) => {
              templateData.push({ id:val.id, width: val.width, height: val.height })
            })
            
          }
        })

        const mediaData = [];
        
        this.state.allContainer.map((value, index) => { 
          
          if(this.state.allContainer[index] !== '')
          {

            this.state.mediaCollection.map((value, mediaIndex) => {
              if(value.id === this.state.allContainer[index])
              {
                
                mediaData.push({ id: value.id, name: value.name, imageUrl: value.imageUrl, type: value.type })
              }
            })

          } else {
            mediaData.push({ id: '', name: '', imageUrl: '' })
          }
          
        })
        
        return templateData.map((value, index) => {

          if(mediaData[index].type === "video/mp4")
          {
            return (
              <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} onClick={()=> { 
    
                  if(this.state.currentContainer !== index)
                  {
                    const currentContent = this.state.allContainer[index]
                    const currenIndex = this.state.contents[index].map(val => { return val.content }).indexOf(currentContent)
                    
                    if(currenIndex !== -1){

                      if(this.state.contents[index][currenIndex].schedule){
                        this.setState({ 
                          selectedSecondEndDate: moment.unix(this.state.contents[index][currenIndex].schedule.end_date),
                          selectedSecondStartDate: moment.unix(this.state.contents[index][currenIndex].schedule.start_date),
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.start_time,
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.end_time,
                          switchSecondChecked: true, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: null });
                      } else {
                        this.setState({ switchSecondChecked: false, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: null });
                      }
                      
                    } else {
                      this.setState({ currentContainer: index, checked: [] }) 
                    }
    
                  }
                  
                }} item>
                <Paper className={ this.state.currentContainer === index ? this.props.classes.container : null } square style={{height: '100%', width:'100%', display: 'flex', justifyContent: 'center'}} >
                  <video style={{ width: '100%' }}  controls  src={mediaData[index] ? mediaData[index].imageUrl : ''} type="video/mp4"></video>
                </Paper>
              </Grid> )
          } else {
            return (
              <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} onClick={()=> { 
    
                  if(this.state.currentContainer !== index)
                  {
                    const currentContent = this.state.allContainer[index]
                    const currenIndex = this.state.contents[index].map(val => { return val.content }).indexOf(currentContent)
                    
                    if(currenIndex !== -1){
                      if(this.state.contents[index][currenIndex].schedule){
                        this.setState({ 
                          selectedSecondEndDate: moment.unix(this.state.contents[index][currenIndex].schedule.end_date),
                          selectedSecondStartDate: moment.unix(this.state.contents[index][currenIndex].schedule.start_date),
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.start_time,
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.end_time,
                          switchSecondChecked: true, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: this.state.contents[index][currenIndex].duration });
                      } else {
                        this.setState({ switchSecondChecked: false, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: this.state.contents[index][currenIndex].duration });
                      }
                    } else {
                     
                      this.setState({ currentContainer: index, checked: [] }) 
                    }
    
                  }
                  
                }} item>
                <Paper className={ this.state.currentContainer === index ? this.props.classes.container : null } square style={{height: '100%', width:'100%'}} >
                  <AutoFitImage imgSize="contain" imgSrc={mediaData[index] ? mediaData[index].imageUrl : ''} style={{/*..your style here..*/}}/>
                </Paper>
              </Grid> )
          }
        })

      } else {

        const mediaData = [];
        
        this.state.allContainer.map((value, index) => { 
          
          
          if(this.state.allContainer[index] && this.state.allContainer[index] !== '')
          {
            
            this.state.mediaCollection.map((val, mediaindex) => {
              if(val.id === this.state.allContainer[index])
              {
                
                mediaData.push({ id: val.id, name: val.name, imageUrl: val.imageUrl, type: val.type })
              }
            })
          } else {
            mediaData.push({ id: '', name: '', imageUrl: '' })
          }
          
        })
       
        
        if(mediaData[0] && mediaData[0].type === "video/mp4")
        {
          return (
            <Grid item>
              <Paper square
                style={{ height: 1920/4, width:1080/4, display: 'flex', justifyContent: 'center' }}
              >
                <video  style={{ width: '100%' }}  controls src={mediaData[0] ? mediaData[0].imageUrl : ''} type="video/mp4"></video>
              </Paper>
            </Grid>)
        } else {

          if(mediaData[0] && mediaData[0].stretch){
            return (
              <Grid item>
                <Paper square
                  style={{ height: 1920/4, width:1080/4 }}
                >
                  <img style={{ width: '100%', height: '100%'}} src={mediaData[0] ? mediaData[0].imageUrl : ''}/>
                </Paper>
              </Grid>)
          } else {
            return (
              <Grid item>
                <Paper square
                  style={{ height: 1920/4, width:1080/4 }}
                >
                  <AutoFitImage imgSize="contain" frameWidth={1080/4 + "px"} frameHeight={1920/4 + "px"} imgSrc={mediaData[0] ? mediaData[0].imageUrl : ''} style={{/*..your style here..*/}}/>
                </Paper>
              </Grid>)
          }
        }
        
      }
      
    }

    renderDialog = () => {
        if(this.state.dialogOption === 'addMedia'){
            return(
                <div>
                    <DialogContent style={{ paddingTop: 20, paddingLeft: 0, paddingRight:0}}>
                    <CustomizedTable 
                        rows={[
                            { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
                            { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
                        ]} 
                        orderBy={'created'}
                        folderCollection={this.state.folderCollection}
                        preview={true}
                        movefile={true}
                        select={true}
                        dataName='media'
                        folderSelection={true}
                        onAddMediaClick={this.onAddMediaClick}/>
                    </DialogContent>
                </div>
            )
        } else if (this.state.dialogOption === 'preview'){

          if(this.state.selectedTemplate !== 'default'){

            const templateData = [];
            this.state.templateCollection.map((value, index) => {
              if(value.id === this.state.selectedTemplate)
              {
                value.container.map((val, index) => {
                  templateData.push({ id:val.id, width: val.width, height: val.height })
                })
              }
            })

            return(
              <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25 }}>
                {templateData.map((value, index) => {
                  return (
                      <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} item>
                        {this.renderListAllCarouselItem( index, value.width/4, value.height/4 )}
                      </Grid>
                    )
                })}
              </DialogContent>
            )
            
          } else {
            return (
              <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25 }}>
                <Grid style={{height: 1920/4, width: 1080/4}} item>
                  {this.renderListAllCarouselItem(0, 1080/4, 1920/4)}
                </Grid>
              </DialogContent>)
          }

        }

        else if (this.state.dialogOption === 'edit'){

          return (
            <DialogContent >
              <Grid container>
                <DayPicker 
                  switchChecked = {this.state.switchSecondChecked}
                  handleSwitchChange={() => this.handleSecondSwitchChange()}
                  selectedStartTime={this.state.selectedSecondStartTime} 
                  selectedEndTime={this.state.selectedSecondEndTime} 
                  selectedStartDate={this.state.selectedSecondStartDate} 
                  selectedEndDate={this.state.selectedSecondEndDate} 
                  openDialogStartDate={() => this.openSecondDialogStartDate()} 
                  openDialogEndDate={() => this.openSecondDialogEndDate()} 
                  openDialogStartTime={() => this.openSecondDialogStartTime()} 
                  openDialogEndTime={() => this.openSecondDialogEndTime()} 
                  closeDialog={() => this.closeDialog()}/>
              </Grid>
            </DialogContent>
          )
        }
    }

    renderScheduleDialog = () => {
      if (this.state.dialogSecondOption === 'StartDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedStartDate}
                onChange={this.handleStartDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'EndDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedEndDate}
                onChange={this.handleEndDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'StartTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedStartTime}
                onChange={this.handleStartTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'EndTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedEndTime}
                onChange={this.handleEndTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondStartDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedSecondStartDate}
                onChange={this.handleSecondStartDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondEndDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedSecondEndDate}
                onChange={this.handleSecondEndDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondStartTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedSecondStartTime}
                onChange={this.handleSecondStartTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondEndTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedSecondEndTime}
                onChange={this.handleSecondEndTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }
    }

    handleStartDateSelect = date => {
      if(date.isSameOrBefore(this.state.selectedEndDate) == true)
      {
        this.setState({selectedStartDate:date, openSecondDialog: false })
      }
      
    }

    handleEndDateSelect = date => {
      if(date.isSameOrAfter(this.state.selectedStartDate) == true)
      {
        this.setState({selectedEndDate:date, openSecondDialog: false })
      }
      
    }

    handleStartTimeSelect = time => {
     
      var beginningTime = moment(time.formatted, 'h:mma');
      var endTime = moment(this.state.selectedEndTime, 'h:mma');

      if(beginningTime.isSameOrBefore(endTime) == true)
      {
        this.setState({selectedStartTime:time.formatted })
      }
      
    }

    handleEndTimeSelect = time => {

      var endTime = moment(time.formatted, 'h:mma');
      var beginningTime = moment(this.state.selectedStartTime, 'h:mma');
      if(endTime.isSameOrAfter(beginningTime) == true)
      {
        this.setState({selectedEndTime:time.formatted })
      }
      
    }

    handleSecondStartDateSelect = date => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1)
      {
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.start_date = date.unix()
      }
      
      if(date.isSameOrBefore(this.state.selectedSecondEndDate) == true)
      {
        this.setState({selectedSecondStartDate:date, openSecondDialog: false, contents: newContents })
      }
      

    }

    handleSecondEndDateSelect = date => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1)
      {
        
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.end_date = date.unix()
      }
      
      if(date.isSameOrAfter(this.state.selectedSecondEndDate) == true)
      {
        this.setState({selectedSecondEndDate:date, openSecondDialog: false, contents: newContents })
      }

    }

    handleSecondStartTimeSelect = time => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1){
        
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.start_time = time.formatted
      }
      
      var beginningTime = moment(time.formatted, 'h:mma');
      var endTime = moment(this.state.selectedSecondEndTime, 'h:mma');
      if(beginningTime.isSameOrBefore(endTime) == true)
      {
        this.setState({selectedSecondStartTime:time.formatted, contents: newContents })
      }
     

    }

    handleSecondEndTimeSelect = time => {
      
      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1){
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.end_time = time.formatted
      }
      
      var endTime = moment(time.formatted, 'h:mma');
      var beginningTime = moment(this.state.selectedSecondStartTime, 'h:mma');
      if(endTime.isSameOrAfter(beginningTime) == true)
      {
        this.setState({selectedSecondEndTime:time.formatted, contents: newContents })

      }
      
    }


    renderListAllCarouselItem = (cIndex, itemWidth, itemHeight) => {
        return this.state.contents[cIndex].map((value, index) => { 

          const currentIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value.content)

          if(value.transition === 'none'){
            return <Fade key={index} timeout={0} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              </Paper>
            </Fade>
          } else if(value.transition === 'fade'){
            return <Fade key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              </Paper>
            </Fade>
          } else if(value.transition === 'zoom'){
            return <Zoom key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              </Paper>
            </Zoom>
          } 
        })
    }

    onAddMediaClick = (selected, itemcount,_switchSecondChecked,
                      _selectedSecondStartDate,
                      _selectedSecondEndDate,
                      _selectedSecondStartTime,
                      _selectedSecondEndTime) => {

      const newContents = [...this.state.contents]

      
      selected.forEach((value, index) => {
        const currentMediaIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value)

        for (var i = 0; i < itemcount; i++) {
          if(this.state.mediaCollection[currentMediaIndex].type === 'video/mp4')
          {
            newContents[this.state.currentContainer].push({ schedule: _switchSecondChecked ? {
              start_date: _selectedSecondStartDate.unix(),
              end_date: _selectedSecondEndDate.unix(),
              start_time: _selectedSecondStartTime,
              end_time: _selectedSecondEndTime,
            } : null, duration: null, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
          } else {
            newContents[this.state.currentContainer].push({ schedule: _switchSecondChecked ? {
              start_date: _selectedSecondStartDate.unix(),
              end_date: _selectedSecondEndDate.unix(),
              start_time: _selectedSecondStartTime,
              end_time: _selectedSecondEndTime,
            } : null, duration: 10, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
          }
        }
        
      })

      var total_mix_content_duration = 0;
      var total_second_ads = 0
      var total_second_tc = 0
      var percentage_tc = 0;
      var percentage_ytn = 0;

      if(this.state.selectedCampaignAds != 'none'){
        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);

        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        

        const contents = [[]];
        const ads_active = [];
  
        if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
  
          var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
  
          for (var i = 0; i < total_content_number; i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(newContents[0][tc_count]);
  

              if(tc_count > newContents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
   
        } else {
          var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(newContents[0][tc_count]);
  
              if(tc_count > newContents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }

        
  
        for (var i = 0; i < contents[0].length; i++) {
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
            total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
          } 
          else 
          {
            total_mix_content_duration += parseInt(contents[0][i].duration)
          }

          if(ads_active[i].active){
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_ads += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_ads += parseInt(contents[0][i].duration)
            }
          }
          else
          {
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_tc += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_tc += parseInt(contents[0][i].duration)
            }
          
          }

        }

        percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
        percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;

      }
      else
      {
        if(this.state.contents[0].length > 0){
          for (var i = 0; i < this.state.contents[0].length; i++) {
            if(this.state.contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
    
              total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
            }
          }
        }

        percentage_tc = 100;
        percentage_ytn = 0;
      }
      
      if(this.state.selectedTemplate !== 'default'){
        const allContainer = []
        this.state.templateCollection.map((value, index) => {
          if(value.id === this.state.selectedTemplate)
          {
            value.container.map((val, index) => {
              if(this.state.allContainer[index] == '' && index == this.state.currentContainer)
              {
                allContainer.push(newContents[this.state.currentContainer][0].content)
              } else {
                allContainer.push(this.state.allContainer[index])
              }
            })
            
          }
        })

        this.setState({ percentage_tc, percentage_ytn, total_mix_content_duration, contents: newContents, openDialog: false, allContainer, contentDuration: newContents[this.state.currentContainer][0].duration, transitionSelected: newContents[this.state.currentContainer][0].transition })
      } else {
        const allContainer = []
        allContainer.push(newContents[this.state.currentContainer][0].content)

        this.setState({ percentage_tc, percentage_ytn, total_mix_content_duration, contents: newContents, openDialog: false, allContainer, contentDuration: newContents[this.state.currentContainer][0].duration, transitionSelected: newContents[this.state.currentContainer][0].transition })
      }

    }

    handleCampaignNameChange = event => {
      this.setState({ campaignName: event.target.value });
    };  

    handleSaveCampaign = () => {
      
      if(this.state.selectedTemplate !== 'default' )
      {
        let templateRef = firebase.database().ref('template/' + this.state.selectedTemplate);

        templateRef.once('value', snapshot => {
          const item = snapshot.val();

          var scheduleContent = {
            start_date: this.state.selectedStartDate.unix() ,
            end_date: this.state.selectedEndDate.unix(),
            start_time: this.state.selectedStartTime,
            end_time: this.state.selectedEndTime
          }
  
          var postData = {
            name: this.state.campaignName == ''? 'default' : this.state.campaignName,
            created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            content: this.state.contents,
            ads_content: this.state.selectedCampaignAds == 'none' ? null : this.state.selectedCampaignAds,
            template: this.state.selectedTemplate,
            template_name: item.name,
            schedule: this.state.switchChecked ? scheduleContent : null,
            change: true,
          };
      
          // Get a key for a new Post.
          if(this.props.editCampaign){

            // Write the new post's data simultaneously in the posts list and the user's post list.
            var updates = {};

            if(this.props.ads){
              updates['/campaign_ads/' + this.state.currentCampaignId] = postData;
            } else {
              updates['/campaign_tc/' + this.state.currentCampaignId] = postData;
            }

            firebase.database().ref().update(updates);

            
          } else {

            var newPostKey = this.props.ads ? firebase.database().ref().child('campaign_ads').push().key : firebase.database().ref().child('campaign_tc').push().key;
      
            // Write the new post's data simultaneously in the posts list and the user's post list.
            var updates = {};

            if(this.props.ads){
              updates['/campaign_ads/' + newPostKey] = postData;
            } else {
              updates['/campaign_tc/' + newPostKey] = postData;
            }
            
            firebase.database().ref().update(updates);
          }
            
        });
      } else {


        var scheduleContent = {
          start_date: this.state.selectedStartDate.unix() ,
          end_date: this.state.selectedEndDate.unix(),
          start_time: this.state.selectedStartTime,
          end_time: this.state.selectedEndTime
        }

        const contents = [[]];

        if(this.state.selectedCampaignAds != 'none' && this.state.contents[0].length != 0){

            const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id }).indexOf(this.state.selectedCampaignAds);

            var duplicate_count = 0;
            var ads_count = 0;
            var tc_count = 0;
            var total_mix_content_duration = 0;
    
            const ads_active = [];
            const allContentActive= []
    
            this.state.contents[0].map(value => { 
              if(value.schedule){
                if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
                  value.active = false
                } else {
                  value.active = true
                }
              } else {
                value.active = true
              }
            })
    
            for (var i = 0; i < this.state.contents[0].length; i++) {
              if(this.state.contents[0][i].active)
              {
                allContentActive.push(this.state.contents[0][i])
              }
            }
      
            if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval - 1) > allContentActive.length){
      
              var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
      
              for (var i = 0; i < total_content_number; i++) {
                
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(allContentActive[tc_count]);
    
                  if(tc_count > allContentActive.length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
      
              } 
      
            } else {
    
              var total_content_number = allContentActive.length / (this.state.selectedCampaignInterval - 1) + allContentActive.length;
      
              for (var i = 0; i < Math.round(total_content_number); i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(allContentActive[tc_count]);
      
                  if(tc_count > allContentActive.length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
              }
            }

        }

        var postData = {
          name: this.state.campaignName == ''? 'default' : this.state.campaignName,
          created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
          updated: firebase.database.ServerValue.TIMESTAMP,
          content: this.state.selectedCampaignAds != 'none' && this.state.contents[0].length != 0 ? contents : this.state.contents,
          ads_content: this.state.selectedCampaignAds == 'none' ? null : this.state.selectedCampaignAds,
          template: this.state.selectedTemplate,
          template_name: 'default',
          schedule: this.state.switchChecked ? scheduleContent : null,
          ads_interval: this.state.selectedCampaignInterval,
        };

        var postDatatc = {
          name: this.state.campaignName == ''? 'default' : this.state.campaignName,
          created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
          updated: firebase.database.ServerValue.TIMESTAMP,
          content: this.state.contents,
          ads_content: this.state.selectedCampaignAds == 'none' ? null : this.state.selectedCampaignAds,
          template: this.state.selectedTemplate,
          template_name: 'default',
          schedule: this.state.switchChecked ? scheduleContent : null,
          ads_interval: this.state.selectedCampaignInterval,
          change: true,
        };
    
        if(!this.props.ads){
          var updatesfull = {};
          updatesfull['/campaign/' + (this.props.editCampaign ? this.state.currentCampaignId : this.state.newPostKey) ] = postData;
          firebase.database().ref().update(updatesfull);
        }
       
    
        // Get a key for a new Post.
        if(this.props.editCampaign){
          
          // Write the new post's data simultaneously in the posts list and the user's post list.
          var updates = {};
          
          if(this.props.ads){
            updates['/campaign_ads/' + this.state.currentCampaignId] = postData;
          } else {
            updates['/campaign_tc/' + this.state.currentCampaignId] = postDatatc;
          }
          
          firebase.database().ref().update(updates);

          for (var i = 0; i < this.state.stationCollection.length; i++)
          {
            if(this.state.stationCollection[i].campaign != this.state.stationCollectionNoChange[i].campaign){
              
              firebase.database().ref("station/" + this.state.stationCollection[i].id).update({ campaign: this.state.stationCollection[i].campaign })
            }
          }
          
        } else {

          // Write the new post's data simultaneously in the posts list and the user's post list.
          var updates = {};
          if(this.props.ads){
            updates['/campaign_ads/' + this.state.newPostKey] = postData;
          } else {
            updates['/campaign_tc/' + this.state.newPostKey] = postDatatc;
          }

          firebase.database().ref().update(updates);

          for (var i = 0; i < this.state.stationCollection.length; i++)
          {
            if(this.state.stationCollection[i].campaign != this.state.stationCollectionNoChange[i].campaign){
              
              firebase.database().ref("station/" + this.state.stationCollection[i].id).update({ campaign: this.state.stationCollection[i].campaign })
            }
          }
        }
      }
      this.props.handleTabsChangeIndex(0)
      
      this.setState({ selectedTemplate:'default', contents: [[]], campaignName: '', allContainer: [], currentContainer: 0, transitionSelected: 'fade', contentDuration: 0, currentSlideIndex: [0] })
    }

    onEditSortableListItem = () => {
      this.setState({ 
        openDialog: true, 
        dialogOption: 'edit', 
        dialogTitle: 'Edit File',
        fullScreenDialog: false, 
        dialogTitleVisible: true
      })
    }

    handleContentDurationChange = name => event => {

        const newContents = [...this.state.contents]
        var total_mix_content_duration = 0;
        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;

        if(event.target.value > 0 ){

          if(this.state.selectedCampaignAds != "none"){
            newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = event.target.value

            const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
            var duplicate_count = 0;
            var ads_count = 0;
            var tc_count = 0;

            const contents = [[]];
            const ads_active = [];
      
            if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
      
              var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
      
              for (var i = 0; i < total_content_number; i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
  
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
      
              } 
      
            } else {
              var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
      
              for (var i = 0; i < Math.round(total_content_number); i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
              }
            }

            for (var i = 0; i < contents[0].length; i++) {
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(contents[0][i].duration)
              }

              if(ads_active[i].active){
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_ads += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_ads += parseInt(contents[0][i].duration)
                }
              }
              else
              {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_tc += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_tc += parseInt(contents[0][i].duration)
                }
              
              }

            }

            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
            
          }
          else
          {
            newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = event.target.value

            if(this.state.contents[0].length > 0){
              for (var i = 0; i < this.state.contents[0].length; i++) {
                if(this.state.contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
        
                  total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
                }
              }
            }
            percentage_ytn = 0;
            percentage_tc = 100;
          }

          this.setState({ [name]: event.target.value, contents: newContents, total_mix_content_duration, percentage_ytn, percentage_tc });
         
        } else {

          if(this.state.selectedCampaignAds != "none"){
            newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = 0

            const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
            var duplicate_count = 0;
            var ads_count = 0;
            var tc_count = 0;
            var total_mix_content_duration = 0;
  
            const contents = [[]];
            const ads_active = [];
      
            if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
      
              var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
      
              for (var i = 0; i < total_content_number; i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
  
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
      
              } 
      
            } else {
              var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
      
              for (var i = 0; i < Math.round(total_content_number); i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
              }
            }
  
            var total_second_ads = 0
            var total_second_tc = 0
            var percentage_tc = 0;
            var percentage_ytn = 0;
      
            for (var i = 0; i < contents[0].length; i++) {
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(contents[0][i].duration)
              }

              if(ads_active[i].active){
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_ads += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_ads += parseInt(contents[0][i].duration)
                }
              }
              else
              {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_tc += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_tc += parseInt(contents[0][i].duration)
                }
              
              }

            }

            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
            
            this.setState({ [name]: 0 , contents: newContents, total_mix_content_duration,percentage_tc, percentage_ytn });
          }
          else
          {
              newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = 0

              var total_mix_content_duration = 0;
              var percentage_tc = 100;
              var percentage_ytn = 0;

              if(this.state.contents[0].length > 0){
                for (var i = 0; i < this.state.contents[0].length; i++) {
                  if(this.state.contents[0][i].type == "video/mp4"){
                    const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
          
                    total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
                  } 
                  else 
                  {
                    total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
                  }
                }
              }
                
                this.setState({ [name]: event.target.value, contents: newContents, total_mix_content_duration, percentage_tc, percentage_ytn });
            }
          }

    }

    handleTransitionChange = event => {
      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)
      
      if(currenIndex !== -1){
        this.state.contents[this.state.currentContainer][currenIndex].transition = event.target.value
        this.setState({ transitionSelected: event.target.value });
      } else {
        this.setState({ transitionSelected: event.target.value });
      }

    }

    handleStretchChange = event => {

      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)
      
      if(currenIndex !== -1){
        this.state.contents[this.state.currentContainer][currenIndex].stretch = event.target.checked
        this.setState({ stretchSelected: event.target.checked });
      } else {
        this.setState({ stretchSelected: event.target.checked });
      }

    }
    
    renderTransition(){
      return(
        <Grid container>
          <Grid item xs={12}>
            <TextField
              disabled={this.state.contents[this.state.currentContainer].length > 0 && this.state.contentDuration !== null? false:true}
              fullWidth 
              id="outlined-number"
              label="Duration"
              value={this.state.contentDuration === null ? 0 : this.state.contentDuration}
              InputProps={{ inputProps: { min: 0, max: 3600 } }}
              onChange={this.handleContentDurationChange('contentDuration')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
          </Grid>

        </Grid>
      )
    }

    onPreviewClick = () => {
      this.setState({ 
        openDialog: true, 
        dialogOption: 'preview', 
        dialogTitle: 'Preview',
        fullScreenDialog: false, 
        dialogTitleVisible: false
      })
      
      this.shiftSlide()
    }

    toggleSlide = (index) => event =>{
      
      
      if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = newCurrentSlide[index] + 1
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopSlide(index)
        }
      } else {
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = 0
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopSlide(index)
        }
      }
     
    } 

    toggleNextSlide = (index) => {
      
      
      if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = newCurrentSlide[index] + 1
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopNextSlide(index, nextSlideIndex)
        }
      } else {
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = 0
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopNextSlide(index, nextSlideIndex)
        }
      }
     
    } 

    loopNextSlide = (index, nextSlideIndex) => {
      timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][nextSlideIndex].duration * 1000)
    }

    loopSlide = (index) => {
      timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
    }

    shiftSlide = () => {

      if(this.state.selectedTemplate !== 'default')
      {
        const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
        this.state.templateCollection[currentIndex].container.map((val, index) => {
          if(this.state.contents[index].length > 1){
            if(this.state.contents[index][0].type !== 'video/mp4')
            {
              timeout[index] = this.props.setTimeout(this.toggleSlide(index), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
            } 
          }
        })

      } else {
        if( this.state.contents[0].length > 1 && this.state.contents[0][0].type !== 'video/mp4'){
          timeout[0] = this.props.setTimeout(this.toggleSlide(0), this.state.contents[0][this.state.currentSlideIndex[0]].duration * 1000)
        }
      }
      
    }  

    onClearClick = () => {
      const contents = []
      const allContainer = []
      this.state.contents.map(val => {
        contents.push([])
        allContainer.push('')
      })

      this.setState({ contents, campaignName: '', allContainer, currentContainer: 0, transitionSelected: 'fade', contentDuration: 0, currentSlideIndex: [0] })
    }

    disablePreview = () => {

      let checkedContent = true

      this.state.contents.map(val=> {
        
        if(val.length > 0){
          checkedContent = false
        }

      })

      return checkedContent
    }

    openDialogStartDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'StartDate', 
        dialogSecondTitle: 'Start Date'
      })
    }

    openDialogEndDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'EndDate', 
        dialogSecondTitle: 'End Date'
      })
    }

    openDialogStartTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'StartTime', 
        dialogSecondTitle: 'Start Time'
      })
    }

    openDialogEndTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'EndTime', 
        dialogSecondTitle: 'End Time'
      })
    }

    openSecondDialogStartDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondStartDate', 
        dialogSecondTitle: 'Start Date'
      })
    }

    openSecondDialogEndDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondEndDate', 
        dialogSecondTitle: 'End Date'
      })
    }

    openSecondDialogStartTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondStartTime', 
        dialogSecondTitle: 'Start Time'
      })
    }

    openSecondDialogEndTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondEndTime', 
        dialogSecondTitle: 'End Time'
      })
    }

    closeDialog = () => {
      this.setState({ 
        openSecondDialog: false,
      })
    }

    handleSwitchChange = () => {
      this.setState(state => ({ switchChecked: !state.switchChecked }));
    }

    handleSecondSwitchChange = () => {

      if(!this.state.switchSecondChecked)
      {
        const newContents = [...this.state.contents];
        var scheduleContent = {
          start_date: this.state.selectedSecondStartDate.unix(),
          end_date: this.state.selectedSecondEndDate.unix(),
          start_time: this.state.selectedSecondStartTime,
          end_time: this.state.selectedSecondEndTime,
        }
        
        if(this.state.currentSelectedContent !== -1)
        {
          newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule = scheduleContent
        }
        
        this.setState(state => ({ switchSecondChecked: !state.switchSecondChecked, contents: newContents }));

      } else {
        const newContents = [...this.state.contents];
        if(this.state.currentSelectedContent !== -1)
        {
          newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule = null
        }
        

        this.setState(state => ({ switchSecondChecked: !state.switchSecondChecked, contents: newContents }));
      }
      
    }

    onRemoveSortableListItem = () => {
      const newContents = [...this.state.contents];
      
      if(this.state.currentSelectedContent !== -1 && newContents[this.state.currentContainer].length > 1)
      {
        newContents[this.state.currentContainer].splice(this.state.currentSelectedContent, 1)
        var total_mix_content_duration = 0;
        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;

        if(this.state.selectedCampaignAds != 'none')
        {
          const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);

          var duplicate_count = 0;
          var ads_count = 0;
          var tc_count = 0;
  
          const contents = [[]];
          const ads_active = [];
    
          if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
    
            var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
    
            for (var i = 0; i < total_content_number; i++) {
              
              if(i == duplicate_count){
    
                ads_active.push({ active: true});
                contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
    
                if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                  ads_count = 0;
                } else {
                  ads_count++;
                }
    
                duplicate_count = i + this.state.selectedCampaignInterval;
              } else {
                
                ads_active.push({ active: false});
                contents[0].push(newContents[0][tc_count]);
    
  
                if(tc_count > newContents[0].length - 2){
                  tc_count = 0;
                } else {
                  tc_count++;
                }
              }
    
            } 
     
          } else {
            var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
    
            for (var i = 0; i < Math.round(total_content_number); i++) {
              
              if(i == duplicate_count){
    
                ads_active.push({ active: true});
                contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
    
                if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                  ads_count = 0;
                } else {
                  ads_count++;
                }
    
                duplicate_count = i + this.state.selectedCampaignInterval;
              } else {
                
                ads_active.push({ active: false});
                contents[0].push(newContents[0][tc_count]);
    
                if(tc_count > newContents[0].length - 2){
                  tc_count = 0;
                } else {
                  tc_count++;
                }
              }
            }
          }
  
          
            for (var i = 0; i < contents[0].length; i++) {
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(contents[0][i].duration)
              }

              if(ads_active[i].active){
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_ads += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_ads += parseInt(contents[0][i].duration)
                }
              }
              else
              {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_tc += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_tc += parseInt(contents[0][i].duration)
                }
              
              }

            }

            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
        }
        else
        {
          
          if(this.state.contents[0].length > 0){
            for (var i = 0; i < this.state.contents[0].length; i++) {
              if(this.state.contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
              }
            }

            percentage_tc = 100;
            percentage_ytn = 0;
          }
        }
        
        this.setState({ percentage_tc, percentage_ytn, total_mix_content_duration, contents: newContents})
      }
      
      
    }

    render() {
        const { classes } = this.props;

        return (
          <div className={classes.root}>
            <Dialog
                onClose={this.handleSecondClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.openSecondDialog}
                maxWidth='xl'
            >
              <Tooltip title="Close">
                <Button  onClick={() => this.handleSecondClose() }>
              
                    <CloseIcon fontSize="small" />
                  
                </Button>
            </Tooltip>
            <DialogTitle id="customized-dialog-title" onClose={this.handleSecondClose}>
                {this.state.dialogSecondTitle}
            </DialogTitle>
            
            {this.renderScheduleDialog()}
            </Dialog>
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.openDialog}
                maxWidth='xl'
                fullScreen={this.state.fullScreenDialog}
            >
              <Tooltip title="Close">
                <Button  onClick={() => this.handleClose() }>
              
                    <CloseIcon fontSize="small" />
                  
                </Button>
            </Tooltip>
              {this.state.dialogTitleVisible && !this.state.fullScreenDialog ? 
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {this.state.dialogTitle}
            </DialogTitle> : <div></div>}

            {this.state.fullScreenDialog ? 
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography style={{ color:'white' }} variant="h6" className={classes.title}>
                {this.state.dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar> : <div></div>}
            
            {this.renderDialog()}
            </Dialog>
            <Grid style={{ minHeight:windowHeight }} container justify='flex-start' direction={this.state.isMobile ? "column" : "row"}>
                <Grid style={{ position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , height: '100%', padding: 10 }}>
                    
                    <Grid container>
                      <Grid container>
                        <Grid item xs={6} style={{ paddingRight: 5 }}>
                          <Button fullWidth style={{ marginTop: 10 }} variant="outlined" color="primary" onClick={()=> this.setState({ openDialog: true, fullScreenDialog: true, dialogOption: 'addMedia', dialogTitle: 'Add Media', dialogTitleVisible: true }) }>
                          Add File
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 5, paddingRight: 5 }}>
                          <Button disabled={this.state.contents[this.state.currentContainer].length > 0 ? false : true } fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onEditSortableListItem() }>
                          Edit
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 5  }}>
                          <Button disabled={this.state.contents[this.state.currentContainer].length > 0 ? false : true } fullWidth style={{ marginTop: 10 }} variant="outlined" color="secondary" onClick={()=> this.onRemoveSortableListItem() }>
                          Delete
                          </Button>
                        </Grid>
                        {this.renderTransition()}
                      </Grid>
                      <Grid style={{ position: 'relative', overflowY: 'scroll', height: this.state.isMobile ? '100%' : windowHeight-140, width: this.state.isMobile ? window.innerWidth - 100 : 350 }} container>
                          {this.renderSortable()}
                      </Grid>
                    </Grid>
                    
                </Grid>
                {this.props.ads ? "" : <Grid style={{ backgroundColor: '#efefef', position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , height: '100%', padding: 10 }}>
                    
                    <Grid container>
                      <Grid container>
                        <Grid container>
                          <Grid item xs={9} >
                            <Typography  variant="body2" gutterBottom style={{  height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {this.renderDurationCampaign()}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} style={{ paddingLeft: 10 }}>
                            <Select
                                  disabled={this.state.selectedCampaignAds != 'none' && this.state.contents[0].length > 0 ? false : true}
                                  value={this.state.selectedCampaignInterval}
                                  onChange={this.handleCampaignIntervalChange}
                                  input={
                                  <OutlinedInput
                                      fullWidth
                                      name="folder"
                                      id="outlined-age-simple"
                                  />
                                  }
                              >
                                    <MenuItem value={2}>
                                      <em>1</em>
                                    </MenuItem>
                                    <MenuItem value={3}>
                                      <em>2</em>
                                    </MenuItem>
                                    <MenuItem value={4}>
                                      <em>3</em>
                                    </MenuItem>
                                    <MenuItem value={5}>
                                      <em>4</em>
                                    </MenuItem>
                                    <MenuItem value={6}>
                                      <em>5</em>
                                    </MenuItem>
                                    <MenuItem value={7}>
                                      <em>6</em>
                                    </MenuItem>
                              </Select>
                          </Grid>
                        </Grid>
                        
                        <Grid item xs={12} style={{ marginTop: 7 }}>
                          <Divider/>
                          <Typography  variant="body2" gutterBottom style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            Ads usage percentage [{this.state.percentage_ytn.toFixed(2)}%]
                          </Typography>
                          <LinearProgress variant="determinate" value={this.state.percentage_ytn}/>
                          <Divider/>
                          <Typography  variant="body2" gutterBottom style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            TC usage percentage [{this.state.percentage_tc.toFixed(2)}%]
                          </Typography>
                          <LinearProgress variant="determinate" value={this.state.percentage_tc}/>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 25 }}>
                        <FormControl disabled={this.props.ads ? true : false} style={{ width: "100%" }} fullWidth variant="outlined" >
                              <InputLabel
                                  ref={ref => {
                                  this.InputLabelRef = ref;
                                  }}
                                  htmlFor="outlined-age-simple"
                              >
                                  Ads Campaign
                              </InputLabel>
                              <Select
                                  value={this.state.selectedCampaignAds}
                                  onChange={this.handleCampaignAdsChange}
                                  input={
                                  <OutlinedInput
                                      labelWidth={100}
                                      name="folder"
                                      id="outlined-age-simple"
                                  />
                                  }
                              >

                                    <MenuItem value={'none'}>
                                      <em>none</em>
                                    </MenuItem>
                                  {this.state.campaignAdsCollection.map(n => {
                                  return(
                                      <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                                  )
                                  })}

                              </Select>
                          </FormControl>
                        </Grid>
                        
                      </Grid>
                      <Grid style={{ marginTop: 5, position: 'relative', overflowY: 'scroll', height: this.state.isMobile ? '100%' : windowHeight-255, width: this.state.isMobile ? window.innerWidth-20 : 350 }} container>
                          {this.renderMixContents()}
                      </Grid>
                    </Grid>
                    
                </Grid>}
                <Grid container alignItems="center" justify="center" style={{ position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , backgroundColor: '#dcdcdc' , height: windowHeight, padding: 10}} >
                    <Grid container spacing={0} direction="column" alignItems="center" justify="flex-start" style={{ height: 1920/4, width: 1080/4, backgroundColor: '#262626', marginBottom: 10}} >
                      {this.renderTemplate()}
                    </Grid>
                </Grid>
                <Grid style={{ position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , height: windowHeight, padding: 10 }}>
                  <Grid container>
                    <Grid style={{ paddingRight: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="secondary" onClick={()=> this.handleSaveCampaign()}>
                      Save
                      </Button>
                    </Grid>
                    <Grid style={{ paddingLeft: 5, paddingRight: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onPreviewClick()}>
                      Preview
                      </Button>
                    </Grid>
                    <Grid style={{ paddingLeft: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onClearClick()}>
                      Clear
                      </Button>
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="standard-name"
                    label="Campaign Name"
                    value={this.state.campaignName}
                    onChange={this.handleCampaignNameChange}
                  />
                  <Grid container>
                    <DayPicker 
                      switchChecked = {this.state.switchChecked}
                      handleSwitchChange={() => this.handleSwitchChange()}
                      selectedStartTime={this.state.selectedStartTime} 
                      selectedEndTime={this.state.selectedEndTime} 
                      selectedStartDate={this.state.selectedStartDate} 
                      selectedEndDate={this.state.selectedEndDate} 
                      openDialogStartDate={() => this.openDialogStartDate()} 
                      openDialogEndDate={() => this.openDialogEndDate()} 
                      openDialogStartTime={() => this.openDialogStartTime()} 
                      openDialogEndTime={() => this.openDialogEndTime()} 
                      closeDialog={() => this.closeDialog()}/>
                  </Grid>
                  {!this.props.ads ? <Typography  variant="caption" gutterBottom style={{ marginTop: 15, display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                    Assigned Stations
                  </Typography> : <div></div>}
                  {!this.props.ads ? <Grid style={{ marginTop: 5, position: 'relative', overflowY: 'scroll', height: this.state.isMobile ? '100%' : windowHeight-340, width: this.state.isMobile ? window.innerWidth-20: 350 }} container>
                  {firebase.auth().currentUser.o == "youtv-3b886.firebaseapp.com" ? null : this.renderStation() }
                  </Grid> : <div></div>}
                </Grid>
            </Grid>
          </div>
        );
    }
}

CustomizedAddCampaign.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default compose(
  withStyles(styles),
  ReactTimeout
)(CustomizedAddCampaign);
  